import * as React from "react"

import Layout from "../components/layout"
import Inhaltsverzeichnis from "../components/inhaltsverzeichnis.jsx"

import Seo from "../components/seo"
import Button from "../components/button-link"
import AmazonItem from "../components/amazon-item"

import rare from '../images/hotwheels-rare-logo.png'
import carculture from '../images/car-culture.jpg'
import bluecard from '../images/blue-card.webp'
import Video from "../components/video"

const Stunts = () => (
  <Layout>
    <Seo title="Hot Wheels Stunts"> 
      <script type="application/ld+json">
        {`{
          "@context": "https://schema.org",
          "@type": "ItemList",
          "itemListElement": [
            "Hot Wheels City Cobra Crush",
            "Hot Wheels City Ultimate Gator Car Wash",
            "Hot Wheels ​Korkenzieher-Crash-Trackset",
            "Hot Wheels Action MEGA Crash Superbahn",
            "Hot Wheels Track Builder Unlimited Triple Loop Kit",
            "Hot Wheels Himmelscrash-Turm",
            "Hot Wheels Monster Trucks Stunt Reifen Spielset mit Startrampe",
            "Hot Wheels Track Builder Booster Pack"
          ],
          "itemListOrder": "https://schema.org/ItemListOrderDescending",
          "name": "Die Besten Hot Wheels Stunts"
        }`}
      </script>
    </Seo>
    <div className='content'>
      <div className='row'>
        <div className=''>
          <h1 style={{marginBottom: 15}}>Die besten Hot Wheels Stunts in 2022</h1>
          <p>Wir haben unsere Liste für die besten Hot Wheels Stunts zusammengestellt. Egal ob ihr auf der Suche nach Sprüngen, Loopings oder andere Action seid, bei Hot Wheels werdet ihr bestimmt fündig.</p>
          <h2 id="smallkids" className="border" style={{marginTop: 75}}>Stunts für jüngere Kinder</h2>
          <p>Für die jüngeren Kinder gibt es viele coole Stunts von Hot Wheels. Besonders empfehlen können wir diese hier.</p>

          <AmazonItem 
            imgUrl="https://m.media-amazon.com/images/I/61cVrs+DItL._AC_SL1500_.jpg" 
            altText="City Cobra Crush"
            url="https://amzn.to/3z9zBXq" 
            title="Hot Wheels City Cobra Crush"
            text="Ein cooles Stunt Bahnset um die Stadt zu erweitern. Wie der Name schon sagt, handelt es sich bei dieser Strecke um eine riesige Kobra, auf die man Autos schießt. Die Kobra schließt ihr Maul, um ein Auto zu fangen, und du kannst das gefangene Auto retten, indem du ein Auto auf ihren Rücken schießt und einen Auslöser betätigst, der die Schlange einstürzen und ihr Maul öffnen lässt!"
          />
          <AmazonItem 
            imgUrl="https://m.media-amazon.com/images/I/81MiK1QwgVL._AC_SL1500_.jpg" 
            altText="City Ultimate Gator Car Wash"
            url="https://amzn.to/3LQrTJq" 
            video={{
              pos: "bottom",
              width: "250px",
              alignment: "bottom",
              orientation: "row",
              title: "Ultimate Gator Car Wash",
              description: "Das kurze Video ist auf Englisch aber es hilft dir bestimmt einen Eindruck von der Waschanlage zubekommen",
              srcUrl: "https://www.youtube.com/embed/T4ETytFUTZM",
              duration: "2 Minuten 11 Sekunden",
              channelLink: "https://www.youtube.com/c/hotwheels/videos",
              channelName: "Hot Wheels",
              textAfterVideo: "Wenn du die <b>farbwechselnden Hot Wheels Autos</b> hast, ändern die Autos ihre Farbe, wenn sie durch die Waschanlage fahren."
            }}
            title="Hot Wheels City Ultimate Gator Car Wash"
            text='Die Alligatoren Waschanlage ist eins der <b>coolsten Erweiterungen</b>. Vielleicht nicht super Action geladen aber die farbwechselnden Autos sind ein super cooler Effekt. Es gibt einen Aufzug, an dem man kurbelt, um das Auto nach oben zu bringen, es gibt einen Rädchen, an dem man drehen kann, damit echtes Wasser auf das Auto fällt, man kann dann das Auto die Strecke hinunter in einen "Whirlpool" schieben, dem man entkommen kann. Es gibt auch einen riesigen Alligator, der bereit ist, das Auto zu fressen!'
          />

          <h2 id="loopings" className="border" style={{marginTop: 75}}>Loopings</h2>
          <p> Loopings sind die absoluten Klassiker unter den Stunts und passen in absolut jede Bahn.</p>
          <AmazonItem 
            imgUrl="https://m.media-amazon.com/images/I/71O0bzTix2L._AC_SX679_.jpg" 
            altText="​Korkenzieher-Crash-Trackset"
            url="https://amzn.to/3z85zne" 
            title="Hot Wheels ​Korkenzieher-Crash-Trackset"
            text="Wegen dem wilden Korkenzieher-Design und drei Hochleistungs-Beschleunigern rasen die Autos mit einem wahnsingen Tempo über die Bahn und durch den dreifachen Looping mit drei Crash-Zonen die zu viel Action führen."
          />
          
          <AmazonItem 
            imgUrl="https://m.media-amazon.com/images/I/71CqE9eToCL._AC_SL1500_.jpg" 
            altText="Action MEGA Crash Superbahn"
            url="https://amzn.to/3z7Q6U3"
            title="Action MEGA Crash Superbahn"
            text="Die Kinder können ihre Fahrzeuge auf der Bahn starten, und die Fahrzeuge können auf der Bahn kreisen bis sie in den drei verschiedenen Crash-Zonen einem aufregenden Zusammenstoß immer näher kommen. Mit zwei Abschussvorrichtungen können Kinder ihre Freunde herausfordern und weitere Fahrzeuge auf die Strecke bringen, bis sie in einem dramatischen Crash aufeinandertreffen, der zum Spaß dieses High-Speed-Sets gehört. Über eine Falltür in der Bahn kannst das Set mit anderen verbunden werden."
          />
          
          <AmazonItem 
            imgUrl="https://m.media-amazon.com/images/I/71tNgFlJBSL._AC_SL1500_.jpg"
            altText="Track Builder Unlimited Triple Loop Kit" 
            url="https://amzn.to/3nqZpM0" 
            title="Track Builder Unlimited Triple Loop Kit"
            text="Das Track Builder Unlimited Looping ist das erste Set mit drei Stunt-Loopings und bietet aufregende Stunt-Action. Loopings passen gute in jede Bahn und bringen immer viel Spaß."
          />
        <h2 id="jumps" className="border" style={{marginTop: 75}}>Sprünge</h2>
        <p>Eure Hot Wheels Autos könnt ihr mit diesen Stunts zum fliegen bringen.</p>
          <AmazonItem 
            imgUrl="https://m.media-amazon.com/images/I/61Yf3sjMsYL._AC_SL1000_.jpg"
            altText="Hot Wheels Himmelscrash-Turm" 
            url="https://amzn.to/3K4VihH" 
            title="Hot Wheels Himmelscrash-Turm"
            text="Mit dem eingebauten Beschleuniger werden die Autos an dem 83 cm hohen Turm entlang hochgeschleudert von wo die Autos auf der Bahn wieder herunterfahren. Entlang der Bahn gibt es aber mehrere Sprünge mit vielen Chancen auf Action geladene Crashes."
          />
          <AmazonItem 
            imgUrl="https://m.media-amazon.com/images/I/71d8krie4OS._AC_SL1500_.jpg"
            altText="Hot Wheels Monster Trucks Stunt Reifen Spielset mit Startrampe" 
            url="https://amzn.to/3ZiW4My" 
            title="Hot Wheels Monster Trucks Stunt Reifen Spielset mit Startrampe"
            text='Bei diesem Stunt gibt es erst ein Rennen bis zu einer Rampe, von hier fliegen die Trucks durch einen "Feuerring" und Ziel ist es mit einem Salto im Ring am ende der Bahn zulanden.'
          />
        <h2 id="booster" className="border" style={{marginTop: 75}}>Booster</h2>
        <p>Booster sind selber vielleicht keine Stunts aber jeder Stunt wirkt soviel besser wenn die Autos richtig Geschwindigkeit haben. Deswegen kannst du immer extra Beschleuniger in deiner Bahn gebrauchen.</p>
          <AmazonItem 
            imgUrl="https://m.media-amazon.com/images/I/71MWsb2l2oL._AC_SL1500_.jpg"
            altText="Track Builder Booster Pack" 
            url="https://amzn.to/42H7vQX" 
            title="Track Builder Booster Pack"
            text="Der erneuerte Power Booster, der die Autos mit hoher Geschwindigkeit über die Bahn rasen lässt. Das Booster-Paket enthält ein Auto, einen Power-Booster und 2 Bahnstücke."
          />
        </div>
        <div className='content-list-wrapper' style={{width: 240, minWidth: 240, marginLeft: 25}}>
            <Inhaltsverzeichnis content={[
                {
                    id: "smallkids",
                    text: "Für Jüngere Kinder"
                }, 
                {
                    id: "loopings",
                    text: "Loopings",
                },
                {
                    id: "jumps",
                    text: "Sprünge",
                },
                {
                    id: "booster",
                    text: "Beschleuniger"
                }
            ]}/>
        </div>
       
       </div>
       
     </div>
    
   
  </Layout>
)

export default Stunts
